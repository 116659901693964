import axios from 'axios';
// const OSS = require('ali-oss');
import OSS from 'ali-oss';
import {v4 as uuidv4} from 'uuid';
// import Readable from 'stream'

// client
// .list()
// .then(result => {
//   // console.log('objects: %j', Array.from(result.objects).slice(0, 10));
//   console.log('object number: %j', Array.from(result.objects).length);
//   return 'hello world';
// })

export async function uploadToOSSInBlob(blob) {
  const response =
    await axios.get("https://api.kjob.tech/v1/oss/sts-upload-token?suffix=txt",
      { headers: {'Content-Type': 'application/json'} },
    );
  console.log(response.data);
  const responseData = response.data;
  const now = Date.now();
  const expires = new Date(responseData.expiration).getTime();
  console.log("now", now);
  console.log("expires", expires);
  const filename = responseData.path;
  console.log("filename", filename);
  console.log("expires - now", expires - now);

  const config = {
    region: 'oss-cn-hangzhou',
    // accessKeyId: 'LTAI5t5q9YTVAijsrqr62fCY',
    // accessKeySecret: 'SWJUw4vR5JK5ti8j5W349L0RhMy6fs',
    accessKeyId: responseData.access_key_id,
    accessKeySecret: responseData.access_key_secret,
    stsToken: responseData.security_token,
    bucket: 'interview-bot'
  }
  console.log("config", config);
  const client = new OSS(config);
  // const filename = "voice-input/" + uuidv4() + ".webm";

  // client
  //   .list()
  //   .then(result => {
  //     // console.log('objects: %j', Array.from(result.objects).slice(0, 10));
  //     console.log('object number: %j', Array.from(result.objects).length);
  //     return 'hello world';
  //   })

  // const res = await client.list()
  // console.log("total: ", Array.from(res.objects).length);
  const result = await client.put(filename, new OSS.Buffer(['Hello OSS']));
  console.log(result);

  // try {
  //   // Use the 'put' method to upload the Blob
  //   // const s = new Readable();
  //   // s.write("test string");
  //   // s.end()
  //   // const result = await client.put(filename, new OSS.Buffer('hello world'));
  //   const file = 'test.txt';
  //   const filepath = responseData.path;
  //   const options = {
  //     meta: { temp: "demo" },
  //     mime: "json",
  //     headers: { "Content-Type": "text/plain" },
  //   };
  //   const result = await client.put(filepath, new OSS.Buffer('hello world'), options);
  //   console.log(result);
  // } catch (error) {
  //   console.error('Error uploading Blob:', error);
  //   throw error;
  // }
}


export async function uploadToOSSInBlobTmp(blob, suffix) {
  const response =
    await axios.post("https://api.kjob.tech/v1/oss/upload?suffix=" + suffix,
      blob,
      { headers: {'Content-Type': 'application/json'} },
    );
  console.log(response);
  return response.data;
}
