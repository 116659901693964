// src/features/roundSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const roundSlice = createSlice({
  name: 'round',
  initialState: {
    value: 0,
    started: false,
    ended: false,
    position: "resume interview",
    resumeUrl: "",
    roundCount: 0,
    interviewerMessageUuid: "",
    // interviewerAudioUrl: "https://interview-bot.oss-cn-hangzhou.aliyuncs.com/voice-message/45c4f68d-d45b-44ce-a625-f24c19c8aa75.mp3",
    interviewerAudioUrl: null,
    // interviewerSubtitle: "您将面试一个python工程师的职位",
    interviewerSubtitle: "",
    candidateSubtitle: "等待您的回答。。",
  },
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    changePosition: (state, action) => {
      console.log("change position", action.payload);
      state.position = action.payload.position;
    },
    uploadResume: (state, action) => {
      console.log("upload resume", action.payload);
      state.resumeUrl = action.payload.resumeUrl;
    },
    startInterview: (state, action) => {
      console.log("start interview", action.payload);
      state.started = true;
      state.interviewerMessageUuid = action.payload.interviewerMessageUuid;
      state.interviewerAudioUrl = action.payload.audioUrl;
      state.interviewerSubtitle = action.payload.content;
    },
    answerQuestion: (state, action) => {
      console.log("answer questino", action.payload);
      state.interviewerMessageUuid = action.payload.interviewerMessageUuid;
      state.interviewerAudioUrl = action.payload.audioUrl;
      state.interviewerSubtitle = action.payload.content;
      state.candidateSubtitle = action.payload.candidateSubtitle;
      if (action.payload.content && action.payload.content.startsWith("您的面试已经结束")) {
        console.log("interview has ended");
        state.ended = true;
      }
    }
  },
});

// Export actions
export const { increment, decrement, changePosition, uploadResume, startInterview, answerQuestion } = roundSlice.actions;

// Export the reducer
export default roundSlice.reducer;
