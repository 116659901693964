import logo from './logo.svg';
import './App.css';

import axios from 'axios';
import { Button, Image, Select } from 'antd';
import { AudioFilled, StopFilled, UploadOutlined } from '@ant-design/icons';
import MicRecorder from 'mic-recorder-to-mp3';
import React, { useState, useRef } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { AudioRecorder } from 'react-audio-voice-recorder';
import Popup from 'react-popup';
import { useSelector, useDispatch } from 'react-redux';

import { uploadToOSSInBlobTmp } from './common/ossUtils';
import { increment, decrement, changePosition, uploadResume, startInterview, answerQuestion } from './features/roundSlice';


const record = {
  position:'relative',
  borderRadius:'50px'
}

const stop = {
  position:'relative',
  borderRadius:'50px'
}

const Mp3Recorder = new MicRecorder({ 
  bitRate: 64 ,
  prefix: "data:audio/wav;base64,",
});


async function handleStartButtonClick(position, resumeUrl, dispatch) {
  // const x = document.getElementById("startButton");
  // if (x.style.display === "none") {
  //   x.style.display = "block";
  // } else {
  //   x.style.display = "none";
  // }

  // const response = await axios.get(
  //   'https://api.kjob.tech/'
  // );
  // console.log(response.data);

  if ((position == "resume interview" || position == "resume advice") && (!resumeUrl)) {
    console.log("需要上传简历");
    return;
  }

  const data = {
    "sender": "chengyuan",
    "start_new_conversation": true,
    "position": position,
    "resume_url": resumeUrl,
  };
  const response =
    await axios.post("https://api.kjob.tech/v1/interview/single-round",
      data,
      { headers: {'Content-Type': 'application/json'} },
    );
  console.log(data, response.data);
  const assistantMessage = response.data.assistant_message;
  const payload = {
    "interviewerMessageUuid": assistantMessage.uuid,
    "audioUrl": assistantMessage.audio_url,
    "content": assistantMessage.content,
  }
  dispatch(startInterview(payload));

  // const x = document.getElementById("interviewerAudioPlayer");
  // if (x.current) {
  //   x.current.play();
  // }
}


async function handleRecordingComplete(blob, position, interviewerMessageUuid, dispatch) {
  console.log("handle recording complete");
  console.log(blob);
  // const url = URL.createObjectURL(blob);
  // const audio = document.createElement("audio");

  const candidateAudioUrl = await uploadToOSSInBlobTmp(blob, "mp3");
  console.log("audio url", candidateAudioUrl);
  const data = {
    "sender": "chengyuan",
    "start_new_conversation": false,
    "position": position,
    "last_message_uuid": interviewerMessageUuid,
    "audio_message_url": candidateAudioUrl,
    // "audio_message_url": "",
    // "text_message": "我不知道这个问题的答案",
  }
  const response =
    await axios.post("https://api.kjob.tech/v1/interview/single-round",
      data,
      { headers: {'Content-Type': 'application/json'} },
    );
  console.log(data, response.data);
  const assistantMessage = response.data.assistant_message;
  const userMessage = response.data.user_message;
  const payload = {
    "interviewerMessageUuid": assistantMessage.uuid,
    "audioUrl": assistantMessage.audio_url,
    "content": assistantMessage.content,
    "candidateSubtitle": userMessage.content,
  }
  dispatch(answerQuestion(payload));

  // audio.src = url;
  // audio.controls = true;
  // document.body.appendChild(audio);
  // console.log(audio);
  // uploadToOSSInBlob(audio);
}


function App() {
  // var interviewerAudioUrl = "https://interview-bot.oss-cn-hangzhou.aliyuncs.com/voice-message/45c4f68d-d45b-44ce-a625-f24c19c8aa75.mp3";
  // var interviewerSubtitle = "您将面试一个python工程师的职位";
  // var candidateSubtitle = "我的回答。。";
  // var started = false;
  // var round = 0;

  // const recorder = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  const startRecording = () => {
    // if (isBlocked) {
    //   console.warn('Permission Denied');
    // } else {
    //   Mp3Recorder.start().then(() => {
    //     setIsRecording(true);
    //   }).catch(e => console.error(e));
    // }
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(() => {
          console.log('获取麦克风权限成功');
          setIsBlocked(false);
          Mp3Recorder.start().then(() => {
            console.log("开始录音");
            setIsRecording(true);
          });
        })
        .catch(() => {
          console.log('获取麦克风权限失败');
          setIsBlocked(true);
        });
    } else {
      console.log('无法获取麦克风权限');
      setIsBlocked(true);
    }
  };

  const stopRecording = (position, interviewerMessageUuid, dispatch) => {
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        setIsRecording(false);
        console.log("结束录音")
        handleRecordingComplete(blob, position, interviewerMessageUuid, dispatch);
      }).catch(e => console.log(e));

    // navigator.mediaDevices.getUserMedia({ audio: false })
    //   .then(() => {
    //     console.log('关闭麦克风权限成功');
    //     setIsBlocked(false);
    //   })
    //   .catch(() => {
    //     console.log('获取麦克风权限失败');
    //     setIsBlocked(true);
    //   });
  };

  React.useEffect(() => {
    console.log("use effect");
    // recorder.current = new MicRecorder({ bitRate: 128 });

    // if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    //   navigator.mediaDevices.getUserMedia({ audio: true })
    //     .then(() => {
    //       console.log('获取麦克风权限成功');
    //       setIsBlocked(false);
    //     })
    //     .catch(() => {
    //       console.log('获取麦克风权限失败');
    //       setIsBlocked(true);
    //     });
    // } else {
    //   console.log('无法获取麦克风权限');
    //   setIsBlocked(true);
    // }
  });

  // Access the counter value from the Redux store
  const round = useSelector((state) => state.round);
  console.log(round);
  const started = round.started;
  const ended = round.ended;
  const position = round.position;
  const resumeUrl = round.resumeUrl;
  const interviewerMessageUuid = round.interviewerMessageUuid;
  const interviewerAudioUrl = round.interviewerAudioUrl;
  const interviewerSubtitle = round.interviewerSubtitle;
  const candidateSubtitle = round.candidateSubtitle;

  // Dispatch actions to the Redux store
  const dispatch = useDispatch();

  const handleSelectChange = (position) => {
    console.log("selected ", position);
    const payload = {
      "position": position,
    };
    dispatch(changePosition(payload));
  };

  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('开始面试前请先上传简历');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      setUploadStatus("请先选择一个文件");
      return;
    }
    console.log("handle file upload", file);
    const resumeUrl = await uploadToOSSInBlobTmp(file, "docx");
    console.log("resume url", resumeUrl);
    const payload = {
      "resumeUrl": resumeUrl,
    };
    dispatch(uploadResume(payload));
    setUploadStatus("简历上传成功")
  };

  const onStartButtonClick = async (position, resumeUrl, dispatch) => {
    await handleStartButtonClick(position, resumeUrl, dispatch);
    // if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    //   navigator.mediaDevices.getUserMedia({ audio: true })
    //     .then(() => {
    //       console.log('获取麦克风权限成功');
    //       setIsBlocked(false);
    //     })
    //     .catch(() => {
    //       console.log('获取麦克风权限失败');
    //       setIsBlocked(true);
    //     });
    // } else {
    //   console.log('无法获取麦克风权限');
    //   setIsBlocked(true);
    // }
  };

  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        {/* Edit <code>src/App.js</code> and save to reload. */}
        <Image
          width={500}
          src={"https://interview-bot.oss-cn-hangzhou.aliyuncs.com/assets/digital_male.webp"}
        />
        <ReactAudioPlayer
          id="interviewerAudioPlayer"
          className="Audio-player"
          src={interviewerAudioUrl}
          autoPlay={true}
          controls
        />
        <p className="Subtitle">
          {interviewerSubtitle}
        </p>
        {!started && !ended && 
          <div className="App-control">
            <Select
              defaultValue="resume interview"
              // style={{ width: 120 }}
              onChange={handleSelectChange}
              options={[
                { value: 'resume interview', label: '简历面试' },
                { value: 'resume advice', label: '简历修改' },
                { value: 'python engineer', label: 'python工程师' },
                { value: 'english practice', label: '英语口语训练' },
              ]}
            />
            <div className="File-upload">
              <input type="file" accept=".doc,.docx" onChange={handleFileChange} />
              <button onClick={handleFileUpload}>上传简历</button>
              {uploadStatus && <p className="Upload-status">{uploadStatus}</p>}
            </div>
            <Button id="startButton" className="Start-button" onClick={() => onStartButtonClick(position, resumeUrl, dispatch)} type="primary">AI面试开始</Button>
          </div>}
        {started && !ended &&
          <div className="Audio-recorder">
            <div>
              {!isRecording && 
                <Button onClick={startRecording} disabled={isRecording || isBlocked} style={record}><AudioFilled /></Button>}
              {isRecording &&
                <Button onClick={() => stopRecording(position, interviewerMessageUuid, dispatch)} disabled={!isRecording} style={stop}><StopFilled /></Button>}
            </div>
            <p className="Subtitle">
              {candidateSubtitle}
            </p>
          </div>}
        {ended && <p>面试已经结束</p>}
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
          style={{display:"none"}}
        >
          Learn React
        </a>
      </header>
      <footer className="App-footer">
        沪ICP备2024097442号-1
      </footer>
    </div>
  );
}

export default App;
