import React, { Fragment, useState } from "react";
// import "antd/dist/antd.css";
// import "./index.css";
import { Menu } from "antd";
// import {
//   HomeOutlined,
//   AppstoreOutlined,
//   LineChartOutlined,
//   ApartmentOutlined,
//   IdcardOutlined,
//   RiseOutlined,
//   UserOutlined
// } from "@ant-design/icons";

// const { SubMenu } = Menu;

import { useSelector, useDispatch } from 'react-redux';

import { changeTab } from '../features/roundSlice';

const items = [
    {
      label: 'AI面试',
      key: 'interview',
    },
    {
      label: '简历优化',
      key: 'resume',
    },
    {
      label: '技能提升',
      key: 'skill',
    },
    {
      label: '企业邀约',
      key: 'invite',
    },
  ];

function Header() {
    // const [current, setCurrent] = useState('interview');
    const round = useSelector((state) => state.round);
    const tab = round.tab;
    const onClick = (e) => {
      console.log('click ', e);
      const payload = {
        "tab": e.key,
      };
      dispatch(changeTab(payload));
      // setCurrent(e.key);
    };

    const dispatch = useDispatch();

    return (
      <Fragment>
        <Menu onClick={(e) => onClick(e, dispatch)} selectedKeys={[tab]} mode="horizontal" items={items}>
        </Menu>
      </Fragment>
    );
}

export default Header;
