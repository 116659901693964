
function EnterpriseInterview() {
    return (
        <div>
            当前您没有企业邀约
        </div>
    )
}

export default EnterpriseInterview;
