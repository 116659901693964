
const positionLabelDict = {
    "resume interview": "简历面试",
    "resume advice": "简历修改",
    "python engineer": "python工程师",
    "english practice": "英语口语训练",
}

export function convertToPositionOptions(positionList) {
    const options = [];
    for (const position of positionList) {
        options.push({
            value: position,
            label: positionLabelDict[position],
        })
    }
    return options;
}
