import logo from './logo.svg';
import './App.css';

import axios from 'axios';
import { Button, Image, Select, Card, Spin } from 'antd';
import { AudioFilled, StopFilled, UploadOutlined } from '@ant-design/icons';
import MicRecorder from 'mic-recorder-to-mp3';
import React, { useState, useRef, Fragment } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { AudioRecorder } from 'react-audio-voice-recorder';
import Popup from 'react-popup';
import { useSelector, useDispatch } from 'react-redux';

import { uploadToOSSInBlobTmp } from './common/ossUtils';
import { convertToPositionOptions } from './common/positionUtils';
import { increment, decrement, changePosition, changeRoundLimit, uploadResume, startInterview, answerQuestion, displayFeedback } from './features/roundSlice';
import Header from './components/Header';
import EnterpriseInterview from './pages/EnterpriseInterview'


const record = {
  position:'relative',
  borderRadius:'50px'
}

const stop = {
  position:'relative',
  borderRadius:'50px'
}

const Mp3Recorder = new MicRecorder({ 
  bitRate: 64 ,
  prefix: "data:audio/wav;base64,",
});


async function handleStartButtonClick(position, roundLimit, resumeUrl, dispatch) {
  // const x = document.getElementById("startButton");
  // if (x.style.display === "none") {
  //   x.style.display = "block";
  // } else {
  //   x.style.display = "none";
  // }

  // const response = await axios.get(
  //   'https://api.kjob.tech/'
  // );
  // console.log(response.data);

  const data = {
    "sender": "chengyuan",
    "start_new_conversation": true,
    "position": position,
    "round_limit": roundLimit,
    "resume_url": resumeUrl,
  };
  const response =
    await axios.post("https://api.kjob.tech/v1/interview/single-round",
      data,
      { headers: {'Content-Type': 'application/json'} },
    );
  console.log(data, response.data);
  const assistantMessage = response.data.assistant_message;
  const payload = {
    "interviewerMessageUuid": assistantMessage.uuid,
    "audioUrl": assistantMessage.audio_url,
    "content": assistantMessage.content,
  }
  dispatch(startInterview(payload));

  // const x = document.getElementById("interviewerAudioPlayer");
  // if (x.current) {
  //   x.current.play();
  // }
}


async function handleRecordingComplete(blob, position, interviewerMessageUuid, dispatch) {
  console.log("handle recording complete");
  console.log(blob);
  // const url = URL.createObjectURL(blob);
  // const audio = document.createElement("audio");

  const candidateAudioUrl = await uploadToOSSInBlobTmp(blob, "mp3");
  console.log("audio url", candidateAudioUrl);
  const data = {
    "sender": "chengyuan",
    "start_new_conversation": false,
    "position": position,
    "last_message_uuid": interviewerMessageUuid,
    "audio_message_url": candidateAudioUrl,
    // "audio_message_url": "",
    // "text_message": "我不知道这个问题的答案",
  }
  const response =
    await axios.post("https://api.kjob.tech/v1/interview/single-round",
      data,
      { headers: {'Content-Type': 'application/json'} },
    );
  console.log(data, response.data);
  const assistantMessage = response.data.assistant_message;
  const userMessage = response.data.user_message;
  const payload = {
    "interviewerMessageUuid": assistantMessage.uuid,
    "audioUrl": assistantMessage.audio_url,
    "content": assistantMessage.content,
    "candidateSubtitle": userMessage.content,
  }
  dispatch(answerQuestion(payload));

  if (position == "resume interview" || position == "python engineer") {
    const feedbackData = {
      "sender": "chengyuan",
      "last_message_uuid": interviewerMessageUuid,
      "audio_message_url": candidateAudioUrl,
    }
    const feedbackResponse =
      await axios.post("https://api.kjob.tech/v1/interview/feedback",
        feedbackData,
        { headers: {'Content-Type': 'application/json'} }
      );
    console.log(feedbackData, feedbackResponse.data);
    const feedbackPayload = {
      "score": feedbackResponse.data.score,
      "suggestion": feedbackResponse.data.suggestion,
    }
    dispatch(displayFeedback(feedbackPayload));
  }

  // audio.src = url;
  // audio.controls = true;
  // document.body.appendChild(audio);
  // console.log(audio);
  // uploadToOSSInBlob(audio);
}


function App() {
  // var interviewerAudioUrl = "https://interview-bot.oss-cn-hangzhou.aliyuncs.com/voice-message/45c4f68d-d45b-44ce-a625-f24c19c8aa75.mp3";
  // var interviewerSubtitle = "您将面试一个python工程师的职位";
  // var candidateSubtitle = "我的回答。。";
  // var started = false;
  // var round = 0;

  // const recorder = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [feedbackCardLoading, setFeedbackCardLoading] = useState(false);

  const startRecording = () => {
    // if (isBlocked) {
    //   console.warn('Permission Denied');
    // } else {
    //   Mp3Recorder.start().then(() => {
    //     setIsRecording(true);
    //   }).catch(e => console.error(e));
    // }
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(() => {
          console.log('获取麦克风权限成功');
          setIsBlocked(false);
          Mp3Recorder.start().then(() => {
            console.log("开始录音");
            setIsRecording(true);
          });
        })
        .catch(() => {
          console.log('获取麦克风权限失败');
          setIsBlocked(true);
        });
    } else {
      console.log('无法获取麦克风权限');
      setIsBlocked(true);
    }
  };

  React.useEffect(() => {
    console.log("use effect");
    // recorder.current = new MicRecorder({ bitRate: 128 });

    // if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    //   navigator.mediaDevices.getUserMedia({ audio: true })
    //     .then(() => {
    //       console.log('获取麦克风权限成功');
    //       setIsBlocked(false);
    //     })
    //     .catch(() => {
    //       console.log('获取麦克风权限失败');
    //       setIsBlocked(true);
    //     });
    // } else {
    //   console.log('无法获取麦克风权限');
    //   setIsBlocked(true);
    // }
  });

  // Access the counter value from the Redux store
  const round = useSelector((state) => state.round);
  console.log(round);
  const tab = round.tab;
  const started = round.started;
  const ended = round.ended;
  const positionList = round.positionList;
  const positionOptions = convertToPositionOptions(positionList);
  const position = round.position;
  const roundLimitOptions = [
    { value: 3, label: "总轮数：3" },
    { value: 5, label: "总轮数：5" },
    { value: 10, label: "总轮数：10" }
  ];
  const roundLimit = round.roundLimit;
  const resumeUrl = round.resumeUrl;
  const interviewerMessageUuid = round.interviewerMessageUuid;
  const interviewerAudioUrl = round.interviewerAudioUrl;
  const interviewerSubtitle = round.interviewerSubtitle;
  const candidateSubtitle = round.candidateSubtitle;
  const score = round.score;
  const suggestion = round.suggestion;

  // Dispatch actions to the Redux store
  const dispatch = useDispatch();

  const handleSelectChange = (position) => {
    console.log("selected ", position);
    const payload = {
      "position": position,
    };
    dispatch(changePosition(payload));
  };

  const handleRoundLimitSelectChange = (roundLimit) => {
    console.log("round limit selected ", roundLimit);
    const payload = {
      "roundLimit": roundLimit,
    };
    dispatch(changeRoundLimit(payload));
  };

  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('开始面试前请先上传简历');
  const [startButtonLoading, setStartButtonLoading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      setUploadStatus("请先选择一个文件");
      return;
    }
    console.log("handle file upload", file);
    const fileNameSlices = file.name.split(".");
    const suffix = fileNameSlices[fileNameSlices.length - 1];
    const resumeUrl = await uploadToOSSInBlobTmp(file, suffix);
    console.log("resume url", resumeUrl);
    const payload = {
      "resumeUrl": resumeUrl,
    };
    dispatch(uploadResume(payload));
    setUploadStatus("简历上传成功")
  };

  const onStartButtonClick = async (position, roundLimit, resumeUrl, dispatch) => {
    if ((position == "resume interview" || position == "resume advice") && (!resumeUrl)) {
      console.log("需要上传简历");
      return;
    }
    setStartButtonLoading(true);
    await handleStartButtonClick(position, roundLimit, resumeUrl, dispatch);
    setStartButtonLoading(false);
  };

  const stopRecording = (position, interviewerMessageUuid, dispatch) => {
    Mp3Recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        setIsRecording(false);
        setFeedbackCardLoading(true);
        console.log("结束录音", feedbackCardLoading);
        handleRecordingComplete(blob, position, interviewerMessageUuid, dispatch);
        setFeedbackCardLoading(false);
      }).catch(e => console.log(e));
  };

  if (tab == "invite") {
    return (
        <div className="App">
        <Header />
        <div className="App-body">
          <EnterpriseInterview />
        </div>
        <footer className="App-footer">
          沪ICP备2024097442号-1
        </footer>
      </div>
    )
  }

  return (
    <div className="App">
      <Header className="App-header"/>
      <div className="App-body">
        <div className="App-interview">
          <Image
            width={500}
            src={"https://interview-bot.oss-cn-hangzhou.aliyuncs.com/assets/digital_male.webp"}
          />
          <ReactAudioPlayer
            id="interviewerAudioPlayer"
            className="Audio-player"
            src={interviewerAudioUrl}
            autoPlay={true}
            controls
          />
          <p className="Subtitle">
            {interviewerSubtitle}
          </p>
          {!started && !ended && 
            <div className="App-control">
              <Select
                value={position}
                style={{ width: 150 }}
                onChange={handleSelectChange}
                options={positionOptions}
              />
              <Select
                value={roundLimit}
                style={{ width: 150, marginTop: "1vmin" }}
                onChange={handleRoundLimitSelectChange}
                options={roundLimitOptions}
              />
              <div className="File-upload">
                <div className="File-upload-control">
                  <input type="file" accept=".doc,.docx,.pdf" onChange={handleFileChange} />
                  <button onClick={handleFileUpload}>上传简历</button>
                </div>
                {uploadStatus && <p className="Upload-status">{uploadStatus}</p>}
              </div>
              <Button id="startButton" className="Start-button" loading={startButtonLoading} onClick={() => onStartButtonClick(position, roundLimit, resumeUrl, dispatch)} type="primary">
                {startButtonLoading ? "加载中" : "AI面试开始"}
              </Button>
            </div>}
          {started && !ended &&
            <div className="Audio-recorder">
              <div>
                {!isRecording && 
                  <Button onClick={startRecording} disabled={isRecording || isBlocked} style={record}><AudioFilled /></Button>}
                {isRecording &&
                  <Button onClick={() => stopRecording(position, interviewerMessageUuid, dispatch)} disabled={!isRecording} style={stop}><StopFilled /></Button>}
              </div>
              <p className="Subtitle">
                {candidateSubtitle}
              </p>
            </div>}
          {ended && <p>面试已经结束</p>}
        </div>
        <div className="App-feedback">
          <Card title="评分和反馈" className="App-card">
            {feedbackCardLoading && <Spin />}
            {!feedbackCardLoading && <p>{"评分：" + score}</p>}
            {!feedbackCardLoading && <p>{"反馈：" + suggestion}</p>}
          </Card>
        </div>
      </div>
      <footer className="App-footer">
        沪ICP备2024097442号-1
      </footer>
    </div>
  );
}

export default App;
